<!-- <div class="container">
	<hello></hello>
	<ng-multiselect-dropdown name="city" [placeholder]="'Select City'" [data]="cities" [settings]="dropdownSettings"
		(onDropDownClose)="onDropDownClose()" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
	</ng-multiselect-dropdown>
</div> -->
Home works!!

<!-- <ng-multiselect-dropdown
  [placeholder]="'custom placeholder'"
  [settings]="dropdownSettings"
  [data]="dropdownList"
  (onSelect)="onItemSelect($event)"
  (onSelectAll)="onSelectAll($event)"
>
</ng-multiselect-dropdown> -->


