<div class="container" *ngIf="FirstForm">
  <div class="row justify-content-center align-items-center" style="min-height: 75vh;">
    <div class="col-md-5">
      <div class="custom-width">
        <div class="card-body">
          <h5 class="card-title register-now-css">Register now</h5>
          <div class="form-group w-100">
            <div class="d-flex custom-border-social align-items-start">
              <img src='../../../assets/Images/google.png' alt="Google logo" class="mr-3 mt-1 image-url" width="35">
              <div class="w-100 social-text-css ">Login with Google</div>
            </div>
          </div>
          <div class="form-group w-100">
            <div class="d-flex custom-border-social align-items-start">
              <img src="../../../assets/Images/orchid.svg" alt="Google logo" class="mr-3 mt-1 image-url" width="35">
              <div class="w-100 social-text-css ">Login with ORCID</div>
            </div>
          </div>
          <div class="form-group w-100">
            <div class="d-flex custom-border-social align-items-start">
              <img src="../../../assets/Images/linkdln.png" alt="LinkedIn logo" class="mr-3 mt-1 image-url" style="border-radius: 50% !important;" width="35">
              <div class="w-100 social-text-css ">Login with LinkedIn</div>
            </div>
          </div>
          <div class="or-divider text-center my-3">
            <hr class="d-sm">
            <span class="d-none d-sm-inline small-font">OR</span>
            <hr class="d-sm">
          </div>
          <form [formGroup]="registerForm1" >
            <div class="form-group w-100 ">
              <input type="text" formControlName="firstName" placeholder="Enter your username" class="form-control custom-border-social " [ngClass]="{ 'is-invalid': onInputChange() && f.firstName.errors }" />
              <div *ngIf="f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors.required">Username is required</div>
                <div *ngIf="f.firstName.errors && isInputChangedForUserName">Username must be a valid name</div>
              </div>
              <div class="d-flex align-items-center" *ngIf="(submitted && registerForm1.invalid) && !isInputChangedForUserName">
                <span class="submit-error-validation-css"> User name is required. User name must be a valid name. </span>
              </div>
            </div>
            <div class="form-group w-100">
              <input type="text" formControlName="email" placeholder="Enter your email ID" class="form-control custom-border-social " [ngClass]="{ 'is-invalid': isInputChangedForEmail && f.email.errors }"  />
              <div *ngIf="f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required && !isInputChangedForEmail">Email is required</div>
                <div *ngIf="f.email.errors && !isInputChangedForEmails">Email address must be a valid </div>
              </div>
              <div class="d-flex align-items-center" *ngIf="submitted && !isInputChangedForEmail">
                <span class="submit-error-validation-css"> Email is required. Email address must be valid. </span>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <button type="submit" class="btn btn-register" (click)="onSubmit()">REGISTER </button>
            </div>
            <div class="or-divider text-center my-3">
              <hr class="d-sm">
              <span class="d-none d-sm-inline small-font">OR</span>
              <hr class="d-sm">
            </div>
            <div class="d-flex justify-content-center">
              <button type="submit" class="btn btn-sign-in" [routerLink]="['/login']">Sign In</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="failed-message" tabindex="-1" role="dialog" aria-labelledby="custom-modal-label" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body bg-custom-failed">
        <div class="flex-column justify-content-center align">
          <div class="dialogue-header mt-2 text-center"></div>
          <div class="dialogue-body mb-2 text-center">
            <div class="d-flex justify-content-center margin-top-modal"> easilyusermgmt.icodex.in says </div>
          </div>
          <div class="dialogue-body mb-1 text-center">
            <div class="d-flex justify-content-center margin-top-modal">
              <div class="comtainer-text">
                <div class="d-flex justify-content-center align-items-center">
                  <span class="material-symbols-outlined" style="font-size: 24px !important;">error</span>
                  <span class="custom-text-exist"> Your email ID already exists</span>
                </div>
              </div>
              <!-- </div> -->
            </div>
          </div>
          <div class="text-center">
            <button type="button" class="btn mt-2 btn-retry " (click)="hideModalFailed()">OK</button>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="custom-modal-regiistered-success" tabindex="-1" role="dialog" aria-labelledby="custom-modal-label" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body bg-custom-failed">
        <div class="flex-column justify-content-center align">
          <div class="dialogue-header mt-2 text-center">
            <div class="d-flex justify-content-center margin-top-modal">
              <div class="d-flex justify-content-center align-items-center">
                <span class="material-symbols-outlined" style="font-size: 24px !important;">error</span>
                <span class="custom-text1"> Check your email</span>
              </div>
            </div>
          </div>
          <div class="dialogue-body mb-1 text-center">
            <div class="d-flex justify-content-center margin-top-modal">
              <div class="comtainer-text">
                <div class="text-wrapper">
                  <p style="margin: 0px;"> check your mail to create your password </p>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center">
            <button type="button" class="btn mt-2 btn-retry " (click)="hideModal()">OK</button>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>