


<div class="container-fluid p-0 master">
    <div class="">
        <app-menubar class=""></app-menubar>
        <!-- <app-right-panel class=""></app-right-panel> -->
    </div>
    <main role="main" class="col main">
        <router-outlet (activate)='onActivate()'></router-outlet>
    </main>
</div>

