<div class="container-fluid">
  <div class="row custom-navbar">
    <div class="col-1"></div>
    <div class="col-2 confirmplus-logo">
      <span><img class="logo-img" src="../../../assets/Images/logo.png" alt="" /></span>
    </div>
 

    <div class="col-1 comanIconclass1">
      <button
      [routerLink]="['/Home']"
        routerLinkActive="active-menu"
        class="navbar-menu-btn testbackground"
        
      >
        <span class="material-symbols-outlined" id="homeIconId"> home </span>
        <span class="navbar-btn-name">Home</span>
      </button>
    </div>
    <div class="col-1 comanIconclass2">
      <button
      [routerLink]="['/dashboard']"
        routerLinkActive="active-menu" 
        class="navbar-menu-btn response-submit-btn"
       
      >
    
        <span  class="material-symbols-outlined ManageIcons"
        
           id="ManageIconId" >
        
        
          Dashboard
        </span>
      
        <button
          class="response-submit-btn disabled-Tabs "
 >
          Dashboard
        </button>
      </button>
    </div>
    <div class="col-1 comanIconclass2">
      <button
      [routerLink]="['/userights']"
        routerLinkActive="active-menu"
        class="navbar-menu-btn response-submit-btn"
       
      >
    
        <span class="material-symbols-outlined ManageIcons" id="ManageIconId" 
        
        >
      
          manage_accounts
        
        </span>
     
        <button
          class="response-submit-btn disabled-Tabs "
       
        >
          Manage 
        </button>
      </button>
    </div>
   
    <div class="col-1 comanIconclass3">
      <button
      [routerLink]="['/article']"
        class="navbar-menu-btn response-submit-btn"
        routerLinkActive="active-menu"
      
      >
     
        <span class="material-symbols-outlined ManageIcons"   id="TemplateIconId">
          category
          
        </span>
       
        <button
          class="response-submit-btn disabled-Tabs "
        
        >
          Product
        </button>
      </button>
    </div>
 


    <div class="col-2 comanIconclass4">
      <button
      
        class="navbar-menu-btn response-submit-btn"
        routerLinkActive="active-menu"
      
      >
        <span class="material-symbols-outlined ManageIcons" id="AccountIconId">
          account_circle
        </span>
      

        <button
          class="response-submit-btn disabled-Tabs"
         
        >
          Account
        </button>
        <span class="account-tab-Icon">
          <i class="far fa-chevron-down"></i
        ></span>
      </button>
    </div>

    <div class="col-1 comanIconclass5">
      <button

        class="navbar-menu-btn"
        routerLinkActive="active-menu"
      
      >
        <span class="material-symbols-outlined" id="HelpIconId"> help </span>
        <span class="navbar-btn-name">Help</span>
      </button>
    </div>
    <div class="col-1 comanIconclass6">
       
          <button class="navbar-menu-btn"  routerLinkActive="active-menu">
        <span class="material-symbols-outlined"> logout </span>
        <span class="navbar-btn-name">Logout</span>
      </button>
    

    </div>
  </div>
</div>