import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { exit } from 'process';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'

})
export class ExcelServiceService {

  // constructor() { }
  // public exportAsExcelFile(json: any[], excelFileName: string): void {

  //   const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  //   console.log('worksheet',worksheet);
  //   const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
  //   this.saveAsExcelFile(excelBuffer, excelFileName);
  // }

  // private saveAsExcelFile(buffer: any, fileName: string): void {
  //   const data: Blob = new Blob([buffer], {
  //     type: EXCEL_TYPE
  //   });
  //   FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  // }


  constructor() { }

  public excelSheets;
  public ExcelData: any = {};
  public lookUpErrorList;
  public journalInfoData: any;
  public isAll:boolean=false;


  // public data;

  getExcelSheetName(sheets) {
    console.log(sheets);
    this.excelSheets = sheets;
  }

  getExcelSheetData(ExcelData,isall) {
    this.ExcelData = ExcelData
   this.isAll= isall;
  }

  getLookupErrorList(list) {
    this.lookUpErrorList = list;
    console.log(this.lookUpErrorList);
  }

  stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }


  async generateExcel() {
    console.log(this.ExcelData)
    console.log(this.ExcelData[0])
    const workbook = new Workbook();
    let header = ["Job ID", "UserID", "Journal Name", "Status"]

    console.log(this.isAll)
   
      for (let sheet in this.ExcelData[0]) {
        // Excel Title, Header, Data
        console.log(this.ExcelData[0][sheet])
        const title = `Content Service WorkFlow : ${sheet} section`;
  
        //console.log("sheeeetttttt",sheet)
        // if (this.excelSheets[sheet] == "Body") {
        //   var header = ['Element(s)', 'Author Edits', 'Categories', 'Sub Categories', 'Severity', 'Edits classification', 'Subcategories count', 'Assessment/Remarks'];
        // }
        /// var header = ['Element(s)', 'Full sentence before author edit', 'Full sentence after author edit', 'Categories', 'Subcategories', 'Severity', 'Edits classification', 'Subcategories count', 'Assessment/Remarks'];            // column names
  
  
        const sheetData = []
  
      
        for (let j of this.ExcelData[0][sheet]) {
          sheetData.push([j.JobId,j.UserId,j.JournalName,j.Status])
        }
        console.log("sheetdata", sheetData)
  
        // Create workbook and worksheet
  
        const worksheet = workbook.addWorksheet(sheet.replace(/ /g, ""));
  
        // // Add Row and formatting
        const titleRow = worksheet.addRow([title]);
        titleRow.font = { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true };
  
        worksheet.addRow([]);
        // const subTitleRow = worksheet.addRow(['Date : 06-09-2020']);
  
        worksheet.mergeCells('A1:K2');
  
  
        worksheet.autoFilter = {
  
          from: 'E4',
          to: 'F4',
        }
  
  
        // // Blank Row
        worksheet.addRow([]);
  
        // // Add Header Row
        const headerRow = worksheet.addRow(header);
  
        // // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'A9A9A9' },
            bgColor: { argb: 'FF0000FF' }
  
          };
          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        });
  
        // // Add Data and Conditional Formatting
  
        for (let d in sheetData) {
          worksheet.addRow(sheetData[d])
        }
  
  
        worksheet.getColumn(1).width = 30;
        worksheet.getColumn(2).width = 30;
        worksheet.getColumn(3).width = 30;
        worksheet.getColumn(4).width = 30;
        worksheet.getColumn(5).width = 30;
        worksheet.getColumn(6).width = 30;
        worksheet.getColumn(7).width = 30;
        worksheet.getColumn(8).width = 30;
    
  
  
        worksheet.addRow([]);
  
        // ###############################################################################
  
  
  
      }
      // Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((sheetData: any) => {
        const finalExcel = new Blob([sheetData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(finalExcel, 'Content Service WorkFlow.xlsx');
      });
    }
  


  

}
