import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-pages',
  template: `
    <app-master></app-master>   
  `,
  styles: [
  ]
})
export class PagesComponent implements OnInit {

  constructor() { 

  }

  ngOnInit(): void {
  }

}
