import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { Routes } from '@angular/router';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


const routes: Routes = [{
  path: '',
  children:[
    {path:'',component:HomeComponent},
    {path:'**', redirectTo:'', pathMatch:'full'}
  ]
}];
@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    NgMultiSelectDropDownModule.forRoot()
    
  ]
})
export class HomeModule { }
