import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-plan-component',
  templateUrl: './product-plan-component.component.html',
  styleUrls: ['./product-plan-component.component.css']
})
export class ProductPlanComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
