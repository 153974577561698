<div class="main-div">
  <div class="container-fluid headDiv">
    <div class="devHeadIn">
      <h2>
        <!-- <img src="../../../assets/Images/logo.png" width="186px" height="68px" /> -->
      </h2>
      <h2>The Easily suite of products</h2>
    </div>
  </div>
 
  <div class="container  mt-5 mb-5  " >
   
    <div class="row d-flex justify-content-center ">
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-6 leftCard">
            <div class="card p-3 mb-2">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-row align-items-center">
                  <div class="ms-0 c-details">
                    <h6 class="mb-0">
                      <!-- <img src="../../../assets/Images/confirmPlus.png" width="120px" height="32px" /> -->
                    </h6>
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <h6 class="heading">Confirm+ </h6>
                <span><p>Manuscript Screening and Document Creation Engine</p></span>
                <div class="mt-2  button-icon button" >
                  <!-- <span class="material-symbols-outlined" (click)="confirmplusurl()"> login </span> -->
                  <span class="lo mslo" >Login</span>
                  <!-- <span class="material-symbols-outlined"> download </span> -->
                  <span class="lo" (click)="confirmplusdownloadurl()">Download</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card p-3 mb-2 cardMoileMs">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-row align-items-center">
                  <div class=" c-details">
                    <h6 class="mb-0">
                      <!-- <img src="../../../assets/Images/speedbe.png" width="120px" height="32px" /> -->
                    </h6>
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <h6 class="heading">Speedbe </h6>
                <p>Design and Pagination System</p>
                <div class="mt-2  button-icon button">
                  <!-- <span class="material-symbols-outlined"(click)="SpeedBe()"> login </span> -->
                  <span class="lo mslo">Login</span>
                  <!-- <span class="material-symbols-outlined"> download </span> -->
                  <span class="lo">Download</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="row d-flex justify-content-center " style=" margin-top: 1.2rem !important;">
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-6 leftCard">
            <div class="card p-3 mb-2">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-row align-items-center">
                  <div class=" c-details">
                    <h6 class="mb-0">
                      <!-- <img src="../../../assets/Images/CleverView.png" width="120px" height="32px" /> -->
                    </h6>
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <h6 class="heading">CleverView </h6>
                <p>Online Editing and Review Platform</p>
                <div class="mt-2  button-icon button">
                  <!-- <span class="material-symbols-outlined"(click)=" CleverViewURL()"> login </span> -->
                  <span class="lo mslo">Login</span>
                  <!-- <span class="material-symbols-outlined"> download </span> -->
                  <span class="lo">Download</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card p-3 mb-2 cardMoileMs">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-row align-items-center">
                  <div class=" c-details">
                    <h6 class="mb-0">
                      <!-- <img src="../../../assets/Images/CoreSpace1.png" width="120px" height="32px" /> -->
                    </h6>
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <h6 class="heading">CoreSpace </h6>
                <p>Journal Product and Style Information Database</p>
                <div class="mt-2 button-icon  button">
                  <!-- <span class="material-symbols-outlined"(click)="Corespaceurl()"> login </span> -->
                  <span class="lo mslo">Login</span>
                  <!-- <span class="material-symbols-outlined"> download </span> -->
                  <span class="lo">Download</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center " style=" margin-top: 1.2rem !important;">
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-6 leftCard">
            <div class="card p-3 mb-2">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-row align-items-center">
                  <div class=" c-details">
                    <h6 class="mb-0">
                      <!-- <img src="../../../assets/Images/CleverView.png" width="120px" height="32px" /> -->
                    </h6>
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <h6 class="heading">VerifyOne </h6>
                <p>hello VerifyOne here!!</p>
                <div class="mt-2  button-icon button">
                  <!-- <span class="material-symbols-outlined"(click)=" CleverViewURL()"> login </span> -->
                  <span class="lo mslo">Login</span>
                  <!-- <span class="material-symbols-outlined"> download </span> -->
                  <span class="lo">Download</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card p-3 mb-2 cardMoileMs">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-row align-items-center">
                  <div class=" c-details">
                    <h6 class="mb-0">
                      <!-- <img src="../../../assets/Images/CoreSpace1.png" width="120px" height="32px" /> -->
                    </h6>
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <h6 class="heading">InSpace </h6>
                <p>Hello Inspace here!!</p>
                <div class="mt-2 button-icon  button">
                  <!-- <span class="material-symbols-outlined"(click)="Corespaceurl()"> login </span> -->
                  <span class="lo mslo">Login</span>
                  <!-- <span class="material-symbols-outlined"> download </span> -->
                  <span class="lo">Download</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center mt-3" >
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-6 leftCard">
            <div class="card p-4 mb-2">
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-row align-items-center">
                  <div class=" c-details">
                    <h6 class="mb-0">
                      <!-- <img src="../../../assets/Images/CoreSpace1.png" width="120px" height="32px" /> -->
                    </h6>
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <h6 class="heading">Blazon </h6>
                <p>hello Blazon here !!</p>
                <div class="mt-2 button-icon button">
                  <!-- <span class="material-symbols-outlined"> login </span> -->
                  <span class="lo mslo">Login</span>
                  <!-- <span class="material-symbols-outlined"> download </span> -->
                  <span class="lo">Download</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
   
  </div>

  <div class="container-fluid footer">
    <button class="FooterButton">
      <span class="msgIcon">
        <span class="material-symbols-outlined"> chat </span>
      </span>  <span style="font-family: Roboto, sans-serif; font-weight: 500; font-size: 18px; line-height: 22px;">Chat with us</span>
    </button>
    <div class="container mt-4 mb-3">
      <div class="row d-flex justify-content-center">
        <div class="col-md-10">
          <div class="footerDisplay">
            <div class="copyRight">© Copyright ibeyond 2023</div>
            <div class="logoIcon">
              <img src="../../../assets/Images/corespace.png" width="119px" height="37px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>