import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { FileDropModule } from 'ngx-file-drop';
import { FileDragNDropDirective } from './file-drag-n-drop.directive';
// import { NgxFileDragDropModule } from "ngx-file-drag-drop";


const routes: Routes = [{
  path: '',
  children:[
    {path:'',component:DashboardComponent},
    {path:'**', redirectTo:'', pathMatch:'full'}
  ]
}];

@NgModule({
  declarations: [DashboardComponent,FileDragNDropDirective],
  imports: [
    CommonModule,    
    FormsModule ,
    RouterModule.forChild(routes),
    MatSelectModule,
    NgSelectModule,
    ReactiveFormsModule,
    MatExpansionModule,
    FileDropModule,
  ]
})
export class DashboardModule { }
