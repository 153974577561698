import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { AuthGuard } from './authentication/guards/auth.guard';
import { ForgotComponent } from '../core/forgot/forgot.component';
import { ResetComponent } from '../core/reset/reset.component';
import { ArticleComponent } from './article/article.component';

import { ReportComponent } from './report/report.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthenticationComponent } from './authentication/authentication.component';


const pagesRoutes: Routes = [
  { path: 'userights', component: PagesComponent, loadChildren: () => import('./userrights/userrights.module').then(m => m.UserrightsModule) },


  {
    path: 'test',
    component: PagesComponent, loadChildren: () => import('./change-password/change-password.module')
      .then(m => m.ChangePasswordModule),

  },


  {
    path: 'dashboard',
    component: PagesComponent, loadChildren: () => import('./dashboard/dashboard.module')
      .then(m => m.DashboardModule),

  },

{
    path: 'Report',
    component: ReportComponent, loadChildren: () => import('./report/report.module').
      then(m => m.ReportModule)
  },
  {
    path: 'article',
    component: PagesComponent, loadChildren: () => import('./article/article.module')
      .then(m => m.ArticleModule),
  },
  {
    path: 'account1',
    loadChildren: () => import('./account1/account1.module')
      .then(m => m.Account1Module)
  },
  { path: 'CreatePassword',
   loadChildren: () => import('./create-password/create-password.module')
   .then(m => m.CreatePasswordModule) },

   
  {
    path: 'account',
    loadChildren: () => import('./account/account.module')
      .then(m => m.AccountModule)
  },
  { path: 'tryme',
   loadChildren: () => import('./tryme/tryme.module')
   .then(m => m.TrymeModule) },

   

  { path: 'Resetpass',
   loadChildren: () => import('./resetpass/resetpass.module')
   .then(m => m.ResetpassModule) },
   { path: 'Resetpassword', loadChildren: () => import('./resetpassword/resetpassword.module')
   .then(m => m.ResetpasswordModule) },

   { path: 'Forgotpassword', loadChildren: () => import('./forgotpassword/forgotpassword.module')
   .then(m => m.ForgotpasswordModule) },
   { path: 'Forgotemail', loadChildren: () => import('./forgotemail/forgotemail.module')
   .then(m => m.ForgotemailModule) },
   { path: 'navbar', loadChildren: () => import('./navbar/navbar.module').then(m => m.NavbarModule) },
   { path: 'Admindashboard', loadChildren: () => import('./admindashboard/admindashboard.module').then(m => m.AdmindashboardModule) },
   { path: 'Home',  component: PagesComponent,loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  {
    path: 'login',
    component: ForgotComponent
  },
  {
    path: 'Registration',
    component: AuthenticationComponent
  },



  {
    path: ':templatelink',
    component: PagesComponent,
    children: [
      // {
      //   path:'article',
      //   component: ArticleComponent
      // },
    ]
  },

 

 


  



  







 





 



 









];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(pagesRoutes)
  ],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
