import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api.service';
import { ExcelServiceService } from 'src/app/excel-service.service';
import { environment } from 'src/environments/environment';
import * as JSZip from "jszip";
import { saveAs } from 'file-saver';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  ExportExcelForm: FormGroup;
  public Intake: any[];
  public CompletenessCheck: any[];
  public AuthorQueries: any[];
  public FinalReview: any[];
  currentSelectedArticle: any;
  isDashboardDetails: any;
  APSCompleted: any;
  APSInprogress: any;
  ApprovedInprogess: any;
  AutoIntake: any;
  FRCompleted: any;
  FRInprogress: any;
  FRRejected: any;
  FinalCompleted: any;
  FreshQCCompleted: any;
  FreshQCInprogess: any;
  ManualIntake: any;
  QCedCompleted: any;
  QCedInprogress: any;
  RejectedInprogess: any;
  ResopnseRecevied: any;
  ResopnseWait: any;
  RevisedInprogress: any;
  TotalManuscript: any;
  Invalid: any;
  files: UploadFile[] = [];
  uploadFileForm: FormGroup;
  Preloader: boolean = false;;

  ExcelData: any = [{

    "Intake": [
      {
        "JobId": "NEWJOBID00212",
        "UserId": 1,
        "JournalName": "JGRB",
        "Status": "1"
      },
      {
        "JobId": "NEWJOBID002121",
        "UserId": 1,
        "JournalName": "PHC3",
        "Status": "1"
      },
      {
        "JobId": "NEWJOBID0021213",
        "UserId": 1,
        "JournalName": "ERPP",
        "Status": "1"
      },
      {
        "JobId": "NEWJOBID00212131111",
        "UserId": 1,
        "JournalName": "GGD",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "NEWJOBID0060",
        "UserId": 1,
        "JournalName": "JGRB",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "NEWJOBID0062",
        "UserId": 1,
        "JournalName": "PHC3",
        "Status": "Link Submitted to Bookin"
      },
      {
        "JobId": "NEWJOBID0061",
        "UserId": 1,
        "JournalName": "GGD",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "NEWJOBID0063",
        "UserId": 1,
        "JournalName": "ERPP",
        "Status": "Completed"
      },
      {
        "JobId": "JYDTD788743487UYH",
        "UserId": 1,
        "JournalName": "JGRB",
        "Status": "Package Received"
      },
      {
        "JobId": "IEYEU2345678EFGHJK",
        "UserId": 1,
        "JournalName": " MODL",
        "Status": "Package Received"
      },
      {
        "JobId": "ICDVRPT00063486ARUMDEXFUL",
        "UserId": 1,
        "JournalName": " MODL",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "DGR4567SREGDR",
        "UserId": 1,
        "JournalName": " MODL",
        "Status": "Completed"
      },
      {
        "JobId": "PALO3445HJ",
        "UserId": 1,
        "JournalName": "JGRC",
        "Status": "cmplte"
      },
      {
        "JobId": "ICDVRPT00063487ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRC",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063488ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRC",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063489ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRB",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063490ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063494ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063491ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRB",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063495ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRC",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063492ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063496ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063497ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRB",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063498ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063499ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063501ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRC",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063502ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063503ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063500ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRC",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063504ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRB",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063508ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRB",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063544ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JOPE\r\n",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063545ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063552ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063550ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRC",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063540ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063549ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JOPE\r\n",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063557ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063558ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRC",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063562ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063563ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JOPE\r\n",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063564ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRB",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063565ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRC",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063566ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "NEW0012JOB",
        "UserId": 1,
        "JournalName": "ECS2",
        "Status": "Link Submited to Bookin"
      },
      {
        "JobId": "ICDVRPT00063567ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRB",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063568ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRC",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "NEWJOBID512",
        "UserId": 1,
        "JournalName": "JGRB",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063568ARUMD123",
        "UserId": 1,
        "JournalName": "JGRB",
        "Status": "Pakage Recived"
      },
      {
        "JobId": "ICDVRPT00073537ARUMDEXFUL",
        "UserId": 1,
        "JournalName": "JGRC",
        "Status": "Link Sent to Bookin"
      }
    ],
    "CompletenessCheak": [
      {
        "JobId": "ICDVRPT00063537ARUMDEXFUL",
        "UserId": 2,
        "JournalName": " MODL",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063534ARUMDEXFUL",
        "UserId": 2,
        "JournalName": " MODL",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063541ARUMDEXFUL",
        "UserId": 2,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00063568ARUMD12345",
        "UserId": 2,
        "JournalName": "JGRB",
        "Status": "Package Received"
      },
      {
        "JobId": "ICDVRPT00063568ARUMD123456",
        "UserId": 2,
        "JournalName": "JGRC",
        "Status": "Package Received"
      },
      {
        "JobId": "ICDVRPT00063568ARUMD1234567",
        "UserId": 2,
        "JournalName": "JGRB",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00073525ARUMDEXFUL",
        "UserId": 2,
        "JournalName": "JGRB",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00073526ARUMDEXFUL",
        "UserId": 2,
        "JournalName": "JGRB",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00073527ARUMDEXFUL",
        "UserId": 2,
        "JournalName": "JOPE\r\n",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00073529ARUMDEXFUL",
        "UserId": 2,
        "JournalName": "JOPE\r\n",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00073531ARUMDEXFUL",
        "UserId": 2,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      },
      {
        "JobId": "ICDVRPT00073532ARUMDEXFUL",
        "UserId": 2,
        "JournalName": "ECS2",
        "Status": "Link Sent to Bookin"
      }
    ],
    "Queries": [
      {
        "JobId": "ERPP643",
        "UserId": 3,
        "JournalName": "ERPP",
        "Status": "Link Sent To Bookin"
      },
      {
        "JobId": "JGRB4565",
        "UserId": 3,
        "JournalName": "JGRB",
        "Status": "1"
      },
      {
        "JobId": "WRCR3434",
        "UserId": 3,
        "JournalName": "WRCR",
        "Status": "1"
      },
      {
        "JobId": "GGD4657",
        "UserId": 3,
        "JournalName": "GGD",
        "Status": "1"
      },
      {
        "JobId": "NEW0000JOB",
        "UserId": 3,
        "JournalName": " MODL",
        "Status": "Completed"
      }
    ],
    "FinalReview": []
  }];
  public deptId: any;
  comfirmplusId: any;
  outsetId: any;
  cleverviewId: any;
  constructor(private http: HttpClient, private excelService: ExcelServiceService, private fb: FormBuilder, private apiService: ApiService,
    private router: Router,
    private toastr: ToastrService
  ) { }
  AuthorForm: boolean = true;
  PublisherForm: boolean = false;
  isComfermP: boolean = false;
  isOutset: boolean = false;
  isCleverview: boolean = false;
  ngOnInit(): void {
    // this.toastr.success('', 'hello', {
    //   timeOut: 3000,
    // });

    this.deptId = sessionStorage.getItem('DeptId')
    this.comfirmplusId = sessionStorage.getItem('comfermplusId')
    this.outsetId = sessionStorage.getItem('OutsetpId')
    this.cleverviewId = sessionStorage.getItem('CleverViewId')

    // this.getdata();
    this.ExportExcelForm = this.fb.group({
      ExportExcel: ['', Validators.required],
    })

    console.log(this.ExcelData)
    this.Intake = [{
      "Intake": this.ExcelData[0].Intake
    }]
    console.log(this.Intake)
    this.CompletenessCheck = [{
      "Completeness Check": this.ExcelData[0].CompletenessCheak
    }]
    this.AuthorQueries = [{
      "Author Queries": this.ExcelData[0].Queries
    }]
    this.FinalReview = [{
      "Final Review": this.ExcelData[0].FinalReview
    }]

    if (this.comfirmplusId==1) {
      this.isComfermP = true;
      this.isOutset = false;
      this.isCleverview = false;
    }
    if (this.outsetId==2) {
      this.isComfermP = false;
      this.isOutset = true;
      this.isCleverview = false;
    }
    if (this.cleverviewId==3) {
      this.isComfermP = false;
      this.isOutset = false;
      this.isCleverview = true;
    }
  }

  public id: any;
  isAll: boolean = false;
  exportAsXLSX(): void {

    if (this.ExportExcelForm.value.ExportExcel == 1) {
      this.ExcelData = this.ExcelData
      this.isAll = true;
    }
    else if (this.ExportExcelForm.value.ExportExcel == 2) {
      this.ExcelData = this.Intake
      this.isAll = false;
    }
    else if (this.ExportExcelForm.value.ExportExcel == 3) {
      this.ExcelData = this.CompletenessCheck
      this.isAll = false;
    }
    else if (this.ExportExcelForm.value.ExportExcel == 4) {
      this.ExcelData = this.AuthorQueries
      this.isAll = false;
    }
    else if (this.ExportExcelForm.value.ExportExcel == 5) {
      this.ExcelData = this.FinalReview
      this.isAll = false;
    }

    this.excelService.getExcelSheetData(this.ExcelData, this.isAll);
    this.excelService.generateExcel();

  }


  change() {
    console.log(this.ExportExcelForm)
  }

  public fileInfo: File | undefined;
  options: any;
  uploadSub: any;
  referenceNo: any;
  isSuccess: boolean = false;
  Message: any;
  isresponse: boolean = false;
  filename: any;
  issendrequest: boolean = true;
  onFileSelected() {
    this.Preloader = true;
    this.isSuccess = false;
    this.isresponse = false;
    if (this.selectedFile.length != 0) {
      const formData = new FormData();
      let j = 0;
      for (let item of this.selectedFile) {
        formData.append(j.toString(), item);
        j++;
      }
      let headers: HttpHeaders;
      headers = new HttpHeaders().set('eAssistantXApiKey', 'Icod0001iC0%YHNujnL1KGBO37UI#AB')
        .set('type', 'VENDOR');
      this.options = {
        headers: headers,
        observe: 'events',
        context: 'HttpContext',
      };
      this.uploadSub = this.http.post('https://uateassistantservices.icodex.in/eassistant/input/V2/file', formData, this.options).subscribe((res: any) => {
        if (res.type == HttpEventType.UploadProgress) {
        } else if (res.type == HttpEventType.Response) {
          console.log(res)
          this.isresponse = true;
          if (res.body.isSuccess == 'true') {
            this.isSuccess = true;
            this.toastr.success('', 'Package Received', {
              timeOut: 3000,
            });
            this.Preloader = false
            this.Message = res.body.Message
            this.referenceNo = res.body.ReferenceNumber;
          }
          else {

            this.Message = res.body.Message
            this.Preloader = false
            this.toastr.error('', 'error', {
              timeOut: 3000,
            });
          }
        }
      }, error => {
        this.toastr.error('', 'error', {
          timeOut: 3000,
        });
        console.log(error);
      })

    }

  }


  // getdata() {
  //   let requestobject: object = {
  //   }
  //   this.apiService.DashboardDetail(requestobject).subscribe(
  //     d => {
  //       console.log(d.AQRecived)
  //       console.log(d)
  //       this.APSCompleted = d.APSCompleted;
  //       this.APSInprogress = d.APSInprogress
  //       this.ApprovedInprogess = d.ApprovedInprogess
  //       this.AutoIntake = d.AutoIntake
  //       this.FRCompleted = d.FRCompleted
  //       this.FRInprogress = d.FRInprogress
  //       this.FRRejected = d.FRRejected
  //       this.FinalCompleted = d.FinalCompleted
  //       this.FreshQCCompleted = d.FreshQCCompleted
  //       this.FreshQCInprogess = d.FreshQCInprogess
  //       this.ManualIntake = d.ManualIntake
  //       this.QCedCompleted = d.QCedCompleted
  //       this.QCedInprogress = d.QCedInprogress
  //       this.RejectedInprogess = d.RejectedInprogess
  //       this.ResopnseRecevied = d.ResopnseRecevied
  //       this.ResopnseWait = d.ResopnseWait
  //       this.RevisedInprogress = d.RevisedInprogress
  //       this.TotalManuscript = d.TotalManuscript
  //       this.Invalid = d.Invalid
  //       // this.AutoIntake = d.AutoIntake
  //       // this.CCInprogress = d.CCInprogress
  //       // this.CCCompleted = d.CCCompleted
  //       // this.AQInprogress = d.AQInprogress
  //       // this.AQResopnseWait = d.AQResopnseWait
  //       // this.AQCompleted = d.AQCompleted
  //       // this.FRInprogress = d.FRInprogress
  //       // this.FRCompleted = d.FRCompleted
  //       // this.Completed = d.Completed
  //       // this.TotalManuscriptDetails = d.TotalManuscript;

  //     }, e => {
  //       console.log(e)
  //     }
  //   )

  // }
  forwardToArticle() {


    localStorage.setItem("isVch", "1");
    this.router.navigate(['/article'])
  }

  isRegular() {


  }

  openConfirmDialog(pIndex): void {
    // // const dialogRef = this.dialog.open(DialogConfirmComponent, {
    // //   panelClass: 'modal-xs'
    // // });
    // dialogRef.componentInstance.fName = this.files[pIndex].name;
    // dialogRef.componentInstance.fIndex = pIndex;


    // dialogRef.afterClosed().subscribe(result => {
    //   if (result !== undefined) {
    //     this.deleteFromArray(result);
    //   }
    // });
  }

  selectedFile: any = [];
  value: any;
  isFileAttached: boolean = false;

  onFileChange(pFileList: File[]) {
    this.issendrequest = false;
    console.log(pFileList)
    this.selectedFile = [];
    if (pFileList && pFileList.length >= 0) {
      if (pFileList.length == 1) {
        for (let item of pFileList) {
          this.selectedFile.push(item)
        }
      }
      else {
        for (let item of pFileList) {
          this.selectedFile.push(item)
        }
      }
      console.log(this.selectedFile)
      this.isFileAttached = true;

    }
    // this.files = Object.keys(pFileList).map(key => pFileList[key]);

  }
  closearticle() {
    this.isSuccess = false;
    this.isresponse = false;
    this.selectedFile = []

  }
  validateFile() {

    console.log("Inside validate")
    if (!this.isFileAttached) {
      this.toastr.warning('', 'Please Upload File', {
        timeOut: 3000,
      });
      return;
    } else {
      const myModal = document.getElementById("closeModal") as HTMLElement;
      myModal.click();
      this.selectedFile = [];
      this.isFileAttached = false;
      this.toastr.success('', 'Document Upload Successfully', {
        timeOut: 3000,
      });
    }

  }

  deleteAttachedFile(name: any, i: any) {
    {
      console.log(this.selectedFile)

      this.selectedFile.splice(i, 1);
      this.isFileAttached = false;
      if (this.selectedFile.length == 0)
        this.issendrequest = true;
    }
  }

  resetModal() {
    this.selectedFile = [];
    this.isFileAttached = false;

  }

  deleteFile(name) {
    console.log(name);
    this.selectedFile = []
    this.isFileAttached = false;
  }
  author() {
    this.AuthorForm = true;
    this.PublisherForm = false;
  }
  Pblisher() {
    this.PublisherForm = true;
    this.AuthorForm = false;
  }
  tryfreeId: any = 1
  Tryfree() {



    sessionStorage.setItem('tryfreeId', this.tryfreeId)

    let url = "https://confirmplusdev.easilyplus.io/"

    window.open(url, '_self');
   


  }


 

}
