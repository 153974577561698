import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { ServicePaths } from 'src/app/shared/service-paths';
import { ApiService } from '../services/api.service';

declare var $: any;
@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})




export class ForgotComponent implements OnInit {


  loginform: FormGroup;
  constructor(private servicePaths: ServicePaths, private router: Router, public toastr: ToastrService, private apiService: ApiService, private fb: FormBuilder, private _router: Router) { }

  showForgetDiv = false;



  ngOnInit(): void {
    $(".modal-backdrop").remove();


    this.GenerateCaptcha1();
    this.loginform = this.fb.group({
      email: [''],
      Password: [''],
    

    })

// console.log(this.loginform.value.email)

  }
  // function to create radnom captcha code
  public GenerateCaptcha1() {
    var chr1 = Math.ceil(Math.random() * 10) + '';
    var chr2 = Math.ceil(Math.random() * 10) + '';
    var chr3 = Math.ceil(Math.random() * 10) + '';
    var str = new Array(4).join().replace(/(.|$)/g, function () { return ((Math.random() * 36) | 0).toString(36)[Math.random() < .5 ? "toString" : "toUpperCase"](); });
    var captchaCode = str + chr1 + chr2 + chr3;
    const txtCaptcha = document.getElementById("txtCaptcha1") as HTMLInputElement;
    txtCaptcha.value = captchaCode
  }

  submitted: boolean = false;
  stripHtml(captcha: any) {
    let tmp = document.createElement("DIV");
  }


  /**
 * Validates the captcha entered by the user and calls the 'getLogin' function if the captcha is correct.
 * Otherwise, displays a modal with an error message and generates a new captcha.
 * @returns {boolean} True if the captcha is correct, false otherwise.
 */


  public validateCaptcha1() {
    this.submitted = true;
 
    var str1 = document.getElementById('txtCaptcha1') as HTMLInputElement;
    var str2 = document.getElementById('txtCompare1') as HTMLInputElement;
    if (str1.value == str2.value) {
      this.getLogin();
    }
    else {
    

      $("#custom-modal-incorrect-captcha").modal("show");
      this.GenerateCaptcha1();
      return false;
    }




  }
  display = "none";

 /**
 * Hides a Bootstrap modal with the ID 'custom-modal'.
 */
  hideModal() {
    $('#custom-modal').modal('hide');

  }

  /**
 * Hides a Bootstrap modal with the ID 'custom-modal-incorrect-captcha'.
 */

  hideWrongCaptcha() {
    $("#custom-modal-incorrect-captcha").modal("hide");
  }


  /**
 * Returns a dictionary of all the form controls in the loginform FormGroup.
 * The keys of the dictionary are the names of the form controls in the HTML.
 * @returns { { [key: string]: AbstractControl } } A dictionary of form controls.
 */

  get f(): { [key: string]: AbstractControl } {
    return this.loginform.controls;
  }


  /**
 * Sends a login request to the API service and handles the response.
 * @returns {void}
 */

  public getLogin() {
// debugger
    let requestobject: object = {
      "UserName": this.loginform.value.email,
      "Password": this.loginform.value.Password,
    }
    console.log("requestobject", requestobject)
    this.apiService.getLogin(requestobject).subscribe(
      d => {
        console.log("Object response is", d)

        // .IsSuccess 
        if (d.IsSuccess== true)

    
        {
           sessionStorage.setItem('Token', '123')
          sessionStorage.setItem('UserID', d.UserId
          )

          this.router.navigate(['/article']);
        

          // let url = "http://easilyuidev.icodex.in/"   

          // let url = "https://confirmplusdev.easilyplus.io/home?Id="+d.UserId 
          // console.log(url)

          // let url = `https://confirmplusdev.easilyplus.io/home?Id=${d?.UserId }`
          // console.log(url)
         
          // let url = "https://uatconfirmplus.easilyplus.io/home?Id="+d.UserId 
          setTimeout(()=>{
            // window.open(url, '_blank');


          },5000)


        }
        else {
          
          $('#custom-modal').modal('show');
          
          this.GenerateCaptcha1();

        }
      }, e => {
        console.log(e)

        $('#custom-modal').modal('show');
        this.GenerateCaptcha1();
      }
    )
  }



  showPassword: boolean = false;

  /**
   * toggles password visbility 
   * @returns {void}
   */
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }


  isInputChangedForEmail = false;
  isInputChangedForPassword = false;
  isInputChangedForPasswordField = false

  /**
 * Updates the state of input fields and checks if the form is invalid.
 * @returns {boolean} Whether or not the form is invalid.
 */

  onInputChange() {
    if (this.loginform?.value?.email?.length > 0) {
      this.isInputChangedForEmail = true;
    }
    if (this.loginform?.value?.Password?.length > 0) {
      this.isInputChangedForPassword = true;
    }
    if (this.loginform?.get('Password')?.dirty || this.loginform?.get('Password')?.touched) {
      this.isInputChangedForPasswordField = true;
    }


    for (let field in this.loginform?.controls) {
      let control = this.loginform.get(field)
      if (control.invalid && (control.dirty || control.touched)) {
        return true;
      }
    }
    return false;

  }
 

}
