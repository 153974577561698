import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesComponent } from './pages.component';
import { AuthenticationModule } from './authentication/authentication.module';
// import { HomeModule } from './home/home.module';
import { PagesRoutingModule } from './pages-routing.module';
import { CoreModule } from '../core/core.module';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
//OTHER IMPORTS
// import { CustomProgressComponent } from './../shared/custom-progress/custom-progress.component';
import { Routes, RouterModule } from '@angular/router';
//For Sceduler



import { PdfViewerModule } from 'ng2-pdf-viewer';
      
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import {MatExpansionModule} from '@angular/material/expansion';

import { DashboardModule } from './dashboard/dashboard.module';
import { ArticleModule } from './article/article.module';

import { ChangePasswordModule } from './change-password/change-password.module';
import { ReportModule } from './report/report.module';
import { ProductListComponentComponent } from './product-list-component/product-list-component.component';
import { ProductPlanComponentComponent } from './product-plan-component/product-plan-component.component';
import { HomeModule } from './home/home.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';



export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  imports: [CommonModule,
    RouterModule, CoreModule,PagesRoutingModule,HomeModule, DashboardModule
    , FormsModule,
    NgSelectModule,
    ArticleModule,ReportModule
    // For Scheduler   
    ,PdfViewerModule
    ,MatExpansionModule,
    TabsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgMultiSelectDropDownModule.forRoot()
  ],
  declarations: [
    PagesComponent,
    ProductListComponentComponent,
    ProductPlanComponentComponent,
   
    
  ],
  providers:[]
})
export class PagesModule { }

//platformBrowserDynamic().bootstrapModule(PagesModule);
