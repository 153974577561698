// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_BASE_URL: 'http://localhost:44328/',




//****dev*****//

  // BaseUrl:"https://corespaceapidev.easilyplus.io/",// working
  BaseUrl:"https://devusermngmt.icodex.in/"

//****QA******//
  // BaseUrl:"https://corespaceapidev.easilyplus.io/",
  // BaseUrl:"https://qausermngmt.icodex.in/"

// ******UAT******
//  BaseUrl:"https://appuatapi.easilyplus.io/"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

