import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { PagesModule } from './pages/pages.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServicePaths } from './shared/service-paths';
import { HttpClientModule, HttpClient} from '@angular/common/http';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatStepperModule} from '@angular/material/stepper';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CoreModule } from './core/core.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TestComponent } from './test/test.component';
import { ForgotComponent } from './core/forgot/forgot.component';
import { ResetComponent } from './core/reset/reset.component';
import { FileDropModule } from 'ngx-file-drop';
import {ApiService} from './core/services/api.service';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';




export function HttpLoaderFactory(http: HttpClient  ) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [ ForgotComponent,
    ResetComponent,
    AppComponent,
    TestComponent,
  
    ],
  imports: [
    CommonModule,
    PagesModule,
    FileDropModule,
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    ClickOutsideModule,
    MatAutocompleteModule,
    NgMultiSelectDropDownModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    AngularEditorModule,
    
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    })
  ],
  providers: [
    ServicePaths,ApiService,
    {provide:'BASE-API-VARIABLE-1', useValue:'loginserviceuri'},
    // {provide : LocationStrategy , useClass: HashLocationStrategy},
    {provide:'BASE-API-VARIABLE-2', useValue:'subscriptionserviceuri'},
    {provide:'BASE-API-VARIABLE-3', useValue:'scheduleserviceuri'},
    {provide:'BASE-API-VARIABLE-4', useValue:'requesturi'},
    {provide:'BASE-API-VARIABLE-5', useValue:'contentserviceuri'},
    {provide:'BASE-API-VARIABLE-6', useValue:'examserviceuri'},
    {provide:'VALIDATION-VARIABLE-1', useValue:'timevalidationforscheduler'},
    {provide:'MODE-VARIABLE-1', useValue:'curriculumsubjectmode'},
    
    {provide:'MEASSGE-VARIABLE-1', useValue:'servererror'},
    {provide:'MEASSGE-VARIABLE-2', useValue:'servernotfound'},
    {provide:'MEASSGE-VARIABLE-3', useValue:'pagenotfound'},
    {provide:'MEASSGE-VARIABLE-4', useValue:'subjectvideonotfound'},
    {provide:'MEASSGE-VARIABLE-5', useValue:'topicvideonotfound'},
    {provide:'MEASSGE-VARIABLE-6', useValue:'datanotfound'},
    {provide:'MEASSGE-VARIABLE-7', useValue:'loading'},
    {provide:'MEASSGE-VARIABLE-8', useValue:'comingsoon'},
    {provide:'MEASSGE-VARIABLE-9', useValue:'subjectdocumentnotfound'},
    {provide:'MEASSGE-VARIABLE-10', useValue:'topicdocumentnotfound'},
    {provide:'MEASSGE-VARIABLE-11', useValue:'assessmentsubjectnotfound'},
    {provide:'MEASSGE-VARIABLE-12', useValue:'assessmenttopicnotfound'},
    {provide:'MEASSGE-VARIABLE-13', useValue:'assignmentsubjectnotfound'},
    {provide:'MEASSGE-VARIABLE-14', useValue:'assignmenttopicnotfound'},
    {provide:'MEASSGE-VARIABLE-15', useValue:'nosubscription'},
    {provide:'MEASSGE-VARIABLE-16', useValue:'practicesubjectnotfound'},
    {provide:'MEASSGE-VARIABLE-17', useValue:'practicetopicnotfound'},
    {provide:'MEASSGE-VARIABLE-18', useValue:'noassignment'},
    {provide:'MEASSGE-VARIABLE-19', useValue:'noassessment'},
    {provide:'MEASSGE-VARIABLE-20', useValue:'nosubmittedassignment'},
    {provide:'MEASSGE-VARIABLE-21', useValue:'nosubmittedassessment'},
    {provide:'MEASSGE-VARIABLE-22', useValue:'livetestsubjectnotfound'},
    {provide:'MEASSGE-VARIABLE-23', useValue:'livetesttopicnotfound'},
    {provide:'MEASSGE-VARIABLE-24', useValue:'nolivetesttoday'},
    {provide:'MEASSGE-VARIABLE-25', useValue:'nolivetestupcoming'},
    {provide:'MEASSGE-VARIABLE-26', useValue:'nolivetestupcompleted'},
    {provide:'MEASSGE-VARIABLE-27', useValue:'nopracticetopic'},
    {provide:'MEASSGE-VARIABLE-28', useValue:'nopracticetest'},
   
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
