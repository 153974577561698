import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  // public getJournalDetails(): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}journal/list`
  //   return this.httpClient.get(url);
  // }
  
  // public getArticleDetails(): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}articletype/list`
  //   return this.httpClient.get(url);
  // }

  // public getRole(): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}lookUp/list`
  //   return this.httpClient.get(url);
  // }

  // public getTaskDetail(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}task/Details`
  //   return this.httpClient.post(url,requestBody);
  // }

  // public getQCdata(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}qcavailable/details`
  //   return this.httpClient.post(url,requestBody);
  // }

  // public getMyTaskDetail(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}available/task`
  //   return this.httpClient.post(url,requestBody);
  // }
  // public getAvailableTaskDetail(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}my/task`
  //   return this.httpClient.post(url,requestBody);
  // }

  // public getAssignUser(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}Assign/list`
  //   return this.httpClient.post(url,requestBody);
  // }

  // public getQueriesSubmit(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}author/submit/all/response`
  //   return this.httpClient.post(url,requestBody);
  // }

  // public insertEditorQueries(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}Editor/query`
  //   return this.httpClient.post(url,requestBody);
  // }
  // public insertCommentonJobId(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}insert/comment`
  //   return this.httpClient.post(url,requestBody);
  // }
  // public getComments(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}comment/details`
  //   return this.httpClient.post(url,requestBody);
  // }
  // public getRejectedReason(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}Rejected/Reson`
  //   return this.httpClient.post(url,requestBody);
  // }
  // public getEditorQueriesSubmit(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}Editor/query/details`
  //   return this.httpClient.post(url,requestBody);
  // }
  // public getLookupData(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}list/lookup`
  //   return this.httpClient.post(url,requestBody);
  // }
  // public getRejectArticle(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}article/reject`
  //   return this.httpClient.post(url,requestBody);
  // }
  // public getLogin(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}login/user`
  //   console.log(url)
  //   return this.httpClient.post(url,requestBody);
  // }
  
  public getLogin(requestBody: object): Observable<any> {
    let  BaseUrl = environment.BaseUrl;
    let url=`${ BaseUrl}user/validate`//login
    console.log(url)
    return this.httpClient.post(url,requestBody);
  }
  public getRegister(requestBody: object): Observable<any> {
    debugger
    let  BaseUrl = environment.BaseUrl;
    let url=`${ BaseUrl}user/Register` //registraion 
    console.log(url)
    return this.httpClient.post(url,requestBody);
  }
  public checkUser(requestBody: object): Observable<any> {
    let  BaseUrl = environment.BaseUrl;
    let url=`${ BaseUrl}user/check`  //login page 
    console.log(url)
    return this.httpClient.post(url,requestBody);
  }
  public Createpassword(requestBody: object): Observable<any> {
    debugger
    let  BaseUrl = environment.BaseUrl;
    let url=`${ BaseUrl}user/CreatePassword`
    console.log(url)
    return this.httpClient.post(url,requestBody);
  }

  public tokendetails(requestBody: object): Observable<any> {
    let  BaseUrl = environment.BaseUrl;
    let url=`${ BaseUrl} user/tokendetails`
    console.log(url)
    return this.httpClient.post(url,requestBody);
  }

  // to get user details based on access token 
    public getUserDetails(token:any): Observable<any> {
    let BaseUrl = environment.BaseUrl;
    let url=`${BaseUrl}Register/get/${token}`
    return this.httpClient.get(url);

  }

  //api to send reset token to user 
  public sendForgetPasswordLink(requestBody: object): Observable<any> {
    let  BaseUrl = environment.BaseUrl;
    let url=`${ BaseUrl}Recover/ForgetPasswordBtn`
    console.log(url)
    return this.httpClient.post(url,requestBody);
  }

   // validate reset token 
   public validateResetToken(requestBody:object): Observable<any> {
    let BaseUrl = environment.BaseUrl;
    let url=`${BaseUrl}Recover/tokenForgetPasswordValidate`
    return this.httpClient.post(url,requestBody);

  }

    // to get user details based on access token 
    public getResetUserDetailsByToken(token:any): Observable<any> {
      let BaseUrl = environment.BaseUrl;
      let url=`${BaseUrl}Recover/get/${token}`
      return this.httpClient.get(url);
  
    }


      //api to  reset password of user 
  public resetPassword(requestBody: object): Observable<any> {
    let  BaseUrl = environment.BaseUrl;
    let url=`${ BaseUrl}Recover/password`
    console.log(url)
    return this.httpClient.post(url,requestBody);
  }





  // public getAquiredata(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}article/details`
  //   console.log(url)
  //   return this.httpClient.post(url,requestBody);
  // }

  // public getDownloadFileFromS3Bucket(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}file/downloading`   
  //   return this.httpClient.post(url,requestBody);
  // }

  // public getAuthorQueries(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}author/query`   
  //   return this.httpClient.post(url,requestBody);
  // }
  // public deleteEditorQueries(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}update/editorqueries`   
  //   return this.httpClient.post(url,requestBody);
  // }
  // public deleteAuthorQueries(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}update/authorqueries`   
  //   return this.httpClient.post(url,requestBody);
  // }
  
  // public getAuthorQueriesDisplay(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}author/query/details`   
  //   return this.httpClient.post(url,requestBody);
  // }
  // public getAuthorQueriesUpdate(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}update/author/response`   
  //   return this.httpClient.post(url,requestBody);
  // }

  // public getEditorQueriesUpdate(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}update/editor/response`   
  //   return this.httpClient.post(url,requestBody);
  // }
  // public getSendEmail(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}email/sending`   
  //   return this.httpClient.post(url,requestBody);
  // }
 
  // public DashboardDetail(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}dashboard/details`   
  //   return this.httpClient.post(url,requestBody);
  // }

  // public updateAQ(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}update/authorqueries`  
  //   return this.httpClient.post(url,requestBody);
  // }

  // public GetDeptWiseUserInfo(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}department/userinfo`  
  //   return this.httpClient.post(url,requestBody);

  // }

  // public GetAttachment(requestBody: object): Observable<any> {
  //   let BaseUrl = environment.BaseUrl;
  //   let url=`${BaseUrl}get/Attchment`  
  //   return this.httpClient.post(url,requestBody);

  // }

  //to get 
  
  

}
