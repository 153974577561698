<div class="container">
  <div class="row justify-content-center align-items-center" style="min-height: 96vh;">
    <!-- Button trigger modal -->
    <div class="col-md-5">
      <div class="card-body">
        <h5 class="card-title register-now-css">Sign In</h5>
        <div class="form-group w-100">
          <div class="d-flex custom-border-social align-items-start">
            <img src='../../../assets/Images/google.png' alt="Google logo" class="mr-3 mt-1 image-url" width="35">
            <div class="w-100 social-text-css ">Login with Google</div>
          </div>
        </div>
        <div class="form-group w-100">
          <div class="d-flex custom-border-social align-items-start">
            <img src="../../../assets/Images/orchid.svg" alt="Google logo" class="mr-3 mt-1 image-url" width="35">
            <div class="w-100 social-text-css ">Login with ORCID</div>
          </div>
        </div>
        <div class="form-group  w-100">
          <div class="d-flex custom-border-social align-items-start">
            <img src="../../../assets/Images/linkdln.png" alt="LinkedIn logo" class="mr-3 mt-1 image-url" style="border-radius: 50% !important" width="35">
            <div class="w-100 social-text-css">Login with LinkedIn</div>
          </div>
        </div>
        <div class="or-divider text-center my-3">
          <hr class="d-sm">
          <span class="d-none d-sm-inline small-font">OR</span>
          <hr class="d-sm">
        </div>
        <form [formGroup]="loginform" (ngSubmit)="validateCaptcha1()">
          <div class="form-group w-100">
            <input type="email" class="form-control  custom-border-social" id="typeEmailX-2" formControlName="email" placeholder="Enter your email" required>
          </div>
          <div class="form-group custom-password">
            <div class="input-group ">
              <input type="{{ showPassword ? 'text' : 'password' }}" class="form-control input-padding  custom-border-social" formControlName="Password" placeholder=" Enter password">
              <i (click)="showPassword = !showPassword" style="cursor: pointer; margin-left: -31px;margin-top: 4px;padding:1.8px;z-index: 99999;">
                <span class="material-symbols-outlined" *ngIf="!showPassword"> visibility_off </span>
                <span class="material-symbols-outlined" *ngIf="showPassword"> visibility </span>
              </i>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div>
              <input type="text" class="mb-3 form-control  captcha-width custom-border-social form-output-captcha" id="txtCaptcha1" (copy)="$event.preventDefault()" disabled>
            </div>
          </div>
          <div class="form-group w-100">
            <input type="text" class="form-control " (cut)="$event.preventDefault()" (copy)="$event.preventDefault()" (paste)="$event.preventDefault()" for="typeEmailX-2" class="form-control  custom-border-social  form-control-lg mt-2" id="txtCompare1" placeholder="Enter Captcha" autocomplete="off">
          </div>
          <div class="d-flex justify-content-center">
            <button type="submit" class="btn btn-login" type="submit" (click)="validateCaptcha1()" [routerLink]="['/login']">SIGN IN</button>
          
          </div>
          <div class="d-flex justify-content-center forget">
            <div style="color: #0F50A6; margin-top: 22px;">Forgot password?</div>
          </div>
          <div class="or-divider text-center my-3">
            <hr class="d-sm">
            <span class="d-none d-sm-inline small-font">OR</span>
            <hr class="d-sm">
          </div>
          <div class="d-flex justify-content-center">
            <!-- <button type="submit" class="btn btn-register" [routerLink]="['/Registration']">If you haven’t registered, <span style="font-size: 19px;"> Register Now</span>
            </button> -->
             <div type="submit" class="btn btn-register">If you haven’t registered, <span style="font-size: 19px;"  [routerLink]="['/Registration']" > Register Now</span>
             </div> 
          </div>
        </form>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="custom-modal" tabindex="-1" role="dialog" aria-labelledby="custom-modal-label" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body bg-custom-failed">
            <div class="flex-column justify-content-center align-items-center">
              <div class="dialogue-header mt-2 text-center">easilyusermgmt.icodex.in says</div>
              <div class="dialogue-body mb-2 text-center">
                <div class="d-flex justify-content-center margin-top-modal">
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="material-symbols-outlined" style="font-size: 24px !important; color: azure; padding-bottom: 2px;">error</span>
                    <span class="custom-text"> Wrong email or password </span>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <button type="button" class="btn mt-2 btn-retry " (click)="hideModal()">RETRY</button>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="custom-modal-incorrect-captcha" tabindex="-1" role="dialog" aria-labelledby="custom-modal-label" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body bg-custom-failed">
            <div class="flex-column justify-content-center align">
              <div class="dialogue-header mt-2 text-center">
                <i class="fa-thin fa-message-exclamation"></i>easilyusermgmt.icodex.in says
              </div>
              <div class="dialogue-body mb-2 text-center">
                <div class="d-flex justify-content-center margin-top-modal">
                  <div class="d-flex justify-content-center align-items-center">
                    <span class="material-symbols-outlined" style="font-size: 24px !important; color: azure;">error</span>
                    <span class=" custom-text"> Incorrect Captcha code </span>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <button type="button" class="btn mt-2 btn-retry " (click)="hideWrongCaptcha()">OK</button>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>