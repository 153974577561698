


Dashboard works!!
<!-- <div class="container-fluid">

    <div class="row">

        <div class="col-md-12 d-flex aligns-item-center justify-content-center">
            <img class="image" src="../../../assets/Images/logo.png" alt="">
        
        </div>

    </div>
    <div *ngIf="isComfermP">
        <header class="mt-2 " style="font-size: larger; font-family: 'Franklin Gothic Medium';"> Confirm+ features and plans!!</header>
    <div class="row mt-4">
            <div class="col-5">
                <div class="card custom-card">
                    <div class="title custom-title">
                        Features 
                    </div>
                    <hr>
                    <div class="card-body">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Cras justo odio_-CP</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
    
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
    
                          </ul>
                        
                        
    
    
                    </div>
                </div>
            </div>
            <div class="col-2 ">
                <div class="card">
                    <div class="card-body">
                        <div class="title">
                         Try me free <br>
                         
                        </div>
                        <a href="#"> Try for One Month</a>
                        <hr>
                   
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
    
                          </ul>
                    </div>
    
                    <div class="Footer">
                        <button class="btn btn-outline-success  mb-2"   [routerLink]="['/tryme']"  type="button">Try me free</button>
                    </div>
                </div>
            </div>
            <div class="col-2 ">
                <div class="card">
                    <div class="card-body">
                        <div class="title">
                            Premium
                        </div>
                        <a href="#"> 10$ user/Month</a>
                        <hr>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
                            
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
    
                            <li class="list-group-item">Cras justo odio1</li>
                        </ul> 
                    </div>
                    <div class="Footer">
                        <button class="btn btn-outline-success mb-2 "  [routerLink]="['/Registration']"  type="button">Buy Now</button>
                    </div>
                </div>
    
            </div>
            <div class="col-2 ">
                <div class="card ">
                    <div class="card-body">
                        <div class="title" >
                            Premium Plus
                        </div>
                        <a href="#"> 20$ user/Month</a>
                        <hr>
    
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
    
                           
                          </ul>
                    </div>
                    <div class="Footer">
                        <button class="btn btn-outline-success mb-2 " [routerLink]="['/Registration']"  type="button">Buy Now</button>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    <div *ngIf="isOutset">
        <header class="mt-2 " style="font-size: larger; font-family: 'Franklin Gothic Medium';"> Outset features and plans!!</header>
        <div class="row mt-4">
            <div class="col-5">
                <div class="card custom-card">
                    <div class="title custom-title">
                        Features 
                    </div>
                    <hr>
                    <div class="card-body">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Cras justo odio-OS</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
    
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
    
                          </ul>
                        
                        
    
    
                    </div>
                </div>
            </div>
            <div class="col-2 ">
                <div class="card">
                    <div class="card-body">
                        <div class="title">
                         Try me free <br>
                         
                        </div>
                        <a href="#"> Try for One Month</a>
                        <hr>
                   
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
    
                          </ul>
                    </div>
    
                    <div class="Footer">
                        <button class="btn btn-outline-success  mb-2"   (click)="Tryfree()" type="button">Try me free</button>
                    </div>
                </div>
            </div>
            <div class="col-2 ">
                <div class="card">
                    <div class="card-body">
                        <div class="title">
                            Premium
                        </div>
                        <a href="#"> 10$ user/Month</a>
                        <hr>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
                            
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
    
                            <li class="list-group-item">Cras justo odio</li>
                        </ul> 
                    </div>
                    <div class="Footer">
                        <button class="btn btn-outline-success mb-2 " [routerLink]="['/Registration']" type="button">Buy Now</button>
                    </div>
                </div>
    
            </div>
            <div class="col-2 ">
                <div class="card ">
                    <div class="card-body">
                        <div class="title" >
                            Premium Plus
                        </div>
                        <a href="#"> 20$ user/Month</a>
                        <hr>
    
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
    
                           
                          </ul>
                    </div>
                    <div class="Footer">
                        <button class="btn btn-outline-success mb-2 " [routerLink]="['/Registration']" type="button">Buy Now</button>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    <div *ngIf="isCleverview">
        <header class="mt-2 " style="font-size: larger; font-family: 'Franklin Gothic Medium';"> CleverView features and Plans!!</header>
        <div class="row mt-4">
            <div class="col-5">
                <div class="card custom-card">
                    <div class="title custom-title">
                        Features 
                    </div>
                    <hr>
                    <div class="card-body">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Cras justo odio-CV</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
    
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
    
                          </ul>
                        
                        
    
    
                    </div>
                </div>
            </div>
            <div class="col-2 ">
                <div class="card">
                    <div class="card-body">
                        <div class="title">
                         Try me free <br>
                         
                        </div>
                        <a href="#"> Try for One Month</a>
                        <hr>
                   
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
    
                          </ul>
                    </div>
    
                    <div class="Footer">
                        <button class="btn btn-outline-success  mb-2"   (click)="Tryfree()" type="button">Try me free</button>
                    </div>
                </div>
            </div>
            <div class="col-2 ">
                <div class="card">
                    <div class="card-body">
                        <div class="title">
                            Premium
                        </div>
                        <a href="#"> 10$ user/Month</a>
                        <hr>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
                            
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
    
                            <li class="list-group-item">Cras justo odio</li>
                        </ul> 
                    </div>
                    <div class="Footer">
                        <button class="btn btn-outline-success mb-2 "  [routerLink]="['/Registration']"  type="button">Buy Now</button>
                    </div>
                </div>
    
            </div>
            <div class="col-2 ">
                <div class="card ">
                    <div class="card-body">
                        <div class="title" >
                            Premium Plus
                        </div>
                        <a href="#"> 20$ user/Month</a>
                        <hr>
    
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Cras justo odio</li>
                            <li class="list-group-item">Dapibus ac facilisis in</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
                            <li class="list-group-item">Morbi leo risus</li>
                            <li class="list-group-item">Porta ac consectetur ac</li>
                            <li class="list-group-item">Vestibulum at eros</li>
    
                           
                          </ul>
                    </div>
                    <div class="Footer">
                        <button class="btn btn-outline-success mb-2 " [routerLink]="['/Registration']"  type="button">Buy Now</button>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
     



</div> -->