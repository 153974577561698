import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TestComponent } from './test/test.component';
import { AuthGuard } from './pages/authentication/guards/auth.guard';

const appRoutes: Routes = [

  {
    path: '',
    loadChildren: () => import('../app/pages/home/home.module')
      .then(m => m.HomeModule)
  },
  {
    path: '**',
    loadChildren: () => import('../app/pages/pages.module')
      .then(m => m.PagesModule), canActivate: [AuthGuard]
  },
  {
    path: '',
    component: TestComponent
  },

];

@NgModule({
  // imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' , useHash: true })],
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
