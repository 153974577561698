import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/core/services/api.service';
declare var $: any;

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent implements OnInit {

  @ViewChild('customFailed', { static: false }) errorLogin: ElementRef
  switchValue: any = true;
  direction: any = 'ltr';
  param = { value: 'world' };
  dir = sessionStorage.getItem("direction");

  registerBtn = true
  RegisterForm!: FormGroup
  submitted = false;

  registerForm1: FormGroup;



  constructor(public translate: TranslateService, private router: Router, private fb: FormBuilder, private apiService: ApiService) {
    translate.addLangs(['en', 'ar', 'hi', 'mr', 'ta', 'kn', 'gu', 'te']);
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr|ar|mr|hi|ta|kn|gu|te/) ? browserLang : 'en');
  }
  FirstForm: boolean = true;
  SecondForm: boolean = false;
  isForgetPageEnabled: boolean = false;

  /**
   * This is a lifecycle hook in Angular that is called when the component is initialized. 
   * In this function, the code first removes any modal backdrop that might be present. It then initializes a registerForm1 FormGroup instance with two form controls,
   *  firstName and email, and their respective validators. 
   * The function also checks if the user is already logged in (by checking the value of the isLoggedIn key in the sessionStorage) and redirects them to the overview page if they are.
   *  Finally, the function sets the language and direction of the page based on the current language and the switchValue (which is a boolean variable).
   * 
   */

   emailPattern: RegExp = /^[a-zA-Z0-9._%+-]+@(icodexsolutions\.com|ibyond\.ai)$/;

  ngOnInit(): void {
    $(".modal-backdrop").remove();



    this.registerForm1 = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(/^[ A-Za-z']*$/)]],
      email:['',[Validators.required, Validators.pattern(this.emailPattern)]]
      // email: ['', [Validators.required, Validators.pattern(/^[A-Za-z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/)]]
     
    });
    

    console.log("Insoide auth")
    if (sessionStorage.getItem("isLoggedIn") == "true") {
      let templink = sessionStorage.getItem("Tempaltelink")
      this.router.navigate([`/${templink}/overview/`]);
    }
    console.log(this.translate.currentLang)
    sessionStorage.setItem('lang', this.translate.currentLang);
    sessionStorage.setItem('direction', 'ltr');
  }

  /**
   *  This function is called when the switchValue is changed. 
   * It sets the direction of the page to either left-to-right (ltr) or right-to-left (rtl) based on the new value of the switch.
   */
  onFlagChange() {
    //alert(this.switchValue);
    if (this.switchValue) {
      this.direction = 'ltr';
      sessionStorage.setItem('direction', 'ltr');
    } else {
      this.direction = 'rtl';
      sessionStorage.setItem('direction', 'rtl');
    }
  }

  /**
   * This function is called when the user selects a new language from the dropdown menu. 
   * It saves the selected language to localStorage.
   * @param event
   * @return void
   */

  public onChange(event): void {  // event will give you full breif of action
    const newVal = event.target.value;
    localStorage.setItem('locale', newVal);
  }


  /**
   * This function is called when the user selects a new language from the radio buttons. 
   * It sets the new language using the translate.use() method.
   * @param val 
   * @return void
   */
  public onChangeLanguageRadio(val) {
    this.translate.use(val);
    sessionStorage.setItem('lang', val);
  }

  /**
   * This function is called whenever the user types a character in the "Username" or "Email" fields. If both fields have values, the "Register" button is enabled.
   * @param X 
   */
  onKeyUp(X: any) {

    if ((this.RegisterForm.value.Username != null) && (this.RegisterForm.value.email != null)) {
      this.registerBtn = false
    }
  }

  isInputChangedForEmail = false;
  isInputChangedForUserName = false;
  /**
   * This function is triggered whenever there is any input change in the registration form.
   * It checks if the input fields for email and username have been changed and sets their respective flags to true. 
   * Then it loops through all the form controls and checks if any of them are invalid and have been touched or dirty. 
   * If such a control is found, it returns true, else it returns false.
   * @returns boolean (true/false)
   */
  onInputChange() {

    if (this.registerForm1?.value?.email?.length > 0) {
      this.isInputChangedForEmail = true;
    }
    if (this.registerForm1?.value?.firstName?.length > 0) {
      this.isInputChangedForUserName = true;
    }

    for (let field in this.registerForm1?.controls) {
      let control = this.registerForm1.get(field)
      if (control.invalid && (control.dirty || control.touched)) {
        return true;
      }
    }
    return false;

  }

  /**
* Hides a Bootstrap modal with the ID 'custom-modal-regiistered-success'.
*/
  hideModal() {
    $('#custom-modal-regiistered-success').modal('hide');

  }

  /**
 * Hides a Bootstrap modal with the ID 'failed-message'.
 */
  hideModalFailed() {
    $("#failed-message").modal("hide");


  }
  isFormInValid = true;

  /**
   * Returns a dictionary of all the form controls in the registerForm1 FormGroup.
   * The keys of the dictionary are the names of the form controls in the HTML.
   * @returns { { [key: string]: AbstractControl } } A dictionary of form controls.
   */
  get f() { return this.registerForm1.controls; }

/**
 * this function will trgiggered when form is submit
 * It first sets the submitted flag to true and checks if the form is invalid. If the form is invalid, the function returns.
 * If the form is valid, it creates a requestobject object containing user input data and sends a request to the server using the apiService to register the user.
 *  It then subscribes to the response from the server and displays a success or failure message based on the response received. 
 * Finally, the submitted flag is set to true and the function checks if the form is invalid again (although this check is unnecessary since it has already been done earlier).
 * @returns 
 * 
 */

async  onSubmit() {



    this.submitted = true;

    if (this.registerForm1.invalid) {

      return;
    }
    debugger

    let requestobject: object = {

      "EmailId": this.registerForm1.value.email,
      "FirstName": this.registerForm1.value.firstName,
      "LastName": "",
      "UserName": "",
      "Password": "",
      "ConfirmPassword": "",
      "ContactNo": ""
    }

    this.isFormInValid = false;

    console.log("requestobject", requestobject)

   await this.apiService.getRegister(requestobject).subscribe(
      response => {
        console.log(response);

        if (!response) {
          $("#failed-message").modal("show");
        } else {
          $("#custom-modal-regiistered-success").modal("show");

        }

      }
    )
   
    this.submitted = true;



  }

 /**
 * Hides a Bootstrap modal with the ID 'customFailed'.
 */
  hideModalError() {
    $("#customFailed").modal("hide");

  }
}




