// import { Component, OnInit } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/core/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
// import JSZip from "jszip";
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { HttpHeaders, HttpEventType, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {
  @ViewChild('myDropDownList') myDropDownList: ElementRef;
  public ArticleInformation: FormGroup;
  myForm: FormGroup;

  Abstractkeyword: FormGroup;
  AuthorDetailsForm: FormGroup;
  uploadFileForm: FormGroup;
  router: any;
  constructor(private fb: FormBuilder,
    private http: HttpClient, private _snackBar: MatSnackBar, public toastr: ToastrService, private apiService: ApiService) { }

  isArticleinformation: boolean = true;
  isAbstractkeyword: boolean = false;
  isAuthordetails: boolean = false;
  isUploadManuscript: boolean = false;
  isComplete: boolean = false;
  isarticle: boolean = true;
  

  isisActivelete: boolean = false;
  isabstarct: boolean = false;
  isauthor: boolean = false;
  isupload: boolean = false;
  isfinal: boolean = false;
  files: UploadFile[] = [];
  file: any;
  user: any;
  model: any;
  keyword: any = [];
  AqDetails: any = [];
  AffiliationDetails: any = [];
  keywordValue: any;
  AffiliationValue: any;
  AuthorValue: any;
  Preloader: boolean = false;
  packageName: any;
  UserRoleForm:boolean= true;
  authoruser1: boolean = false;
  authoruser2: boolean = false;
  authoruser3: boolean = false;
  AuthorForm: boolean = true;
  SecondForm: boolean =false;
  CorrespondingAuthor:boolean=true;
  CoAuthor: boolean= true;
  Reviewer:boolean =true;
  AuthorFrom2:boolean= false;
  AuthorFrom3:boolean =false;
  //keyword:any;
  currentSelectedArticle: any;
  currentSelectedJournal: any;
  currentSelecteddata: any = "ABCDSWSEDF";
  journalDetails: any;
  articleDetails: any;
  roleDetails: any;
  selectedLevel: any;
  getType: any;
  dataresponse: any
  userId:any;
  ngOnInit(): void {
    this.dataresponse = JSON.parse('{"isSuccess":"true","ReferenceNumber":"ICDVRPT00099009ARUMDEXFUL","Message":"Package received"}')
    this.userId=sessionStorage.getItem('UserID')
    console.log(this.userId)

    console.log(this.dataresponse.isSuccess)
    // this.getdata();
    this.uploadFileForm = this.fb.group({
      AttachFile: ['', Validators.required],

    })
    this.myForm = this.fb.group({
      journalName: ['', Validators.required],
      ArticleType: ['', Validators.required],
      ManuscriptTitle: ['', Validators.required],
      articleid: ['', Validators.required]
    })
    this.Abstractkeyword = this.fb.group({
      Abstract: ['', Validators.required],
      Keyword: ['', Validators.required]
    })
    this.AuthorDetailsForm = this.fb.group({
      AuthorName: ['', Validators.required],
      Role: ['', Validators.required],
      Affiliation: ['', Validators.required],

    })
  }

  get Keyword() {

    return this.Abstractkeyword.get("Keyword");
  }
  // getdata() {
  //   this.apiService.getJournalDetails().subscribe(
  //     d => {
  //       console.log(d)
  //       this.journalDetails = d;
  //     }, e => {
  //       console.log(e)
  //     }
  //   )
  //   this.apiService.getArticleDetails().subscribe((data) => {
  //     console.log(data)
  //     this.articleDetails = data; 
  //     console.log(this.articleDetails)

  //     // this.articleDetails.unshift({ Id: "-1", Name: "--Select Article Type--" });
  //     // this.currentSelectedArticle = this.articleDetails[0].Id
  //   });

  //   this.apiService.getRole().subscribe(
  //     d => {
  //       console.log(d)
  //       this.roleDetails = d;
  //     }, e => {
  //       console.log(e)
  //     }
  //   )
  // }
  selectChange($event) {
    //In my case $event come with a id value
    this.model = this.user[$event];

    console.log(this.model);
  }
  getfinal: any;
  articledoi: any;
  ManuscriptTitle: any;
  isSubmit:Boolean=false;

  article() {
    console.log(this.myForm)
    if (this.isarticle) {
      console.log("forward 1")
      if (this.myForm.value.ArticleType == '' || this.myForm.value.ManuscriptTitle == "" || this.myForm.value.articleid == "" || this.myForm.value.journalName == "") {
        this.toastr.warning('', 'Please Select All field', {
          timeOut: 3000,
        });
      }
      else {
        this.isarticle = false;
        this.isabstarct = false; //true
        this.isArticleinformation = true; //true
        this.isAbstractkeyword = false; //true
        this.isAuthordetails = false;
        this.isupload = true;
        this.isUploadManuscript = this.isupload ? true : false;  // false
        this.isComplete = false;
        this.isauthor = false;
      }

    }
    else if (this.isabstarct) {
      this.isarticle = false;
      this.isabstarct = false;
      this.isupload = false;
      this.isauthor = true;
      this.isArticleinformation = false;
      this.isAbstractkeyword = false;
      this.isAuthordetails = true;
      this.isUploadManuscript = true;
      this.isComplete = false;
    }
    else if (this.isauthor) {
      this.isarticle = false;
      this.isabstarct = false;
      this.isupload = true;
      this.isArticleinformation = false;
      this.isAbstractkeyword = false;
      this.isAuthordetails = false;
      this.isUploadManuscript = true;
      this.isComplete = false;
      this.isauthor = false;
    }
    else if (this.isupload) {
      console.log("forward 1-2")
      this.isUploadManuscript = true;
      this.isisActivelete = false;
      console.log(this.articleDetails)
      let isVch = localStorage.getItem("isVch");
      if (isVch === "1") {
        this.isFileAttached = true;
        localStorage.setItem("isVch", "0")
      }
      if (!this.isFileAttached) {
        this.toastr.warning('', 'Please  attach file', {
          timeOut: 3000,
        });
        return;
      }

      this.getType = this.myForm.value.ArticleType
      this.ManuscriptTitle = this.myForm.value.ManuscriptTitle
      this.articledoi = this.myForm.value.articleid
      this.getfinal = this.myForm.value.journalName

      let year = (new Date()).getFullYear()
      let FullDate = (new DatePipe('en-US').transform(new Date(), 'MM-dd-yyyy'));
      let Time = (new DatePipe('en-US').transform(new Date(), 'hh-mm-ss'));
      //JournalAbbr_Year_AID_Manual_Date_Time.zip
      //JGRA_2020_56645_ Manual _2021-07-23_06-10-03.zip
      this.packageName = this.getfinal + '_' + year + '_' + this.articledoi + '_' + 'Manual' + '_' + FullDate + '_' + Time;

      if (this.isFileAttached)
        this.isfinal = true;
      this.isArticleinformation = false;
      this.isAbstractkeyword = false;
      this.isAuthordetails = false;
      this.isUploadManuscript = false;
      this.isComplete = true;
      this.isarticle = false;
      this.isabstarct = false;
      this.isupload = false;
      this.isauthor = false;
      this.isisActivelete = true;
      this.isSubmit=true;



    }


  }
  articleback() {
    console.log(this.selectedFile)
    if (this.isarticle) {
      console.log("BACK1")
      this.isArticleinformation = true;
      this.isUploadManuscript = false;
    }
    else if (this.isabstarct) {
      console.log("back abs")
      this.isarticle = true;
      this.isArticleinformation = true;
      this.isAbstractkeyword = false;
      this.isAuthordetails = false;
      this.isUploadManuscript = false;
      this.isComplete = false;
      this.isabstarct = false;
      this.isupload = false;
      this.isauthor = false;
    }
    else if (this.isauthor) {
      console.log("back auth")

      this.isarticle = false;
      this.isabstarct = true;

      this.isArticleinformation = false;
      this.isAbstractkeyword = true;
      this.isAuthordetails = false;
      this.isUploadManuscript = false;
      this.isComplete = false;
      this.isupload = false;
      this.isauthor = false;
    }
    else if (this.isupload) {
      console.log("BACK2-1")
      this.isArticleinformation = true; //true
      this.isfinal = false;
      this.isarticle = true; //false
      this.isabstarct = false;
      this.isupload = false;
      this.isauthor = false; //true

      this.isAbstractkeyword = false;
      this.isAuthordetails = false; //true
      this.isUploadManuscript = false;
      this.isComplete = false;
      this.isSubmit=false;

    }
    else if (this.isfinal) {
    
      this.isfinal = false;
      this.isarticle = false;
      this.isabstarct = false;
      this.isupload = true;
      this.isArticleinformation = true;
      this.isAbstractkeyword = false;
      this.isAuthordetails = false;
      this.isUploadManuscript = true;
      this.isComplete = false;
      this.isauthor = false;
      this.isSubmit=false;
      // this.isisActivelete = false;
    }
  }
  //select keywork add edit and dlt
  selectKeyword() {
    console.log(this.Abstractkeyword)
    // if (this.Abstractkeyword.value.Keyword != undefined && !this.Abstractkeyword.value.keyword && this.Abstractkeyword.value.keyword != '')
    if (this.Abstractkeyword.value.Keyword)
      this.keyword.push({ Name: this.Abstractkeyword.value.Keyword })

    this.Abstractkeyword.value.Keyword = '';
    this.keywordValue = '';
    console.log(this.Abstractkeyword)
    console.log(this.keyword)

  }

  deleteKeyword(i: any) {
    this.keyword.splice(i, 1);
    console.log(this.keyword);
  }
  editKeyword(name: any) {
    this.keywordValue = name;
  }

  //select AQ and role add edit and dlt
  addAuthorDetails() {
    if (this.AuthorDetailsForm.value.AuthorName && this.AuthorDetailsForm.value.Role != null)
      this.AqDetails.push({ Name: this.AuthorDetailsForm.value.AuthorName, Role: this.AuthorDetailsForm.value.Role })
    this.AuthorValue = "";
  }
  deleteAQandRole(i: any) {
    this.AqDetails.splice(i, 1);
    console.log(this.keyword);
  }

  editAQandRole(name: any, role: any) {
    console.log(name)
    this.AuthorValue = name, role;
  }
  //select Affiloation and role add edit and dlt
  addAffiliationDetails() {
    if (this.AuthorDetailsForm.value.Affiliation)
      this.AffiliationDetails.push({ Name: this.AuthorDetailsForm.value.Affiliation })
    this.AffiliationValue = "";
  }
  deleteAffiliation(i: any) {
    this.AffiliationDetails.splice(i, 1)
  }
  editAffiliation(name: any) {
    console.log(name)
    this.AffiliationValue = name;
  }



  selectedFile: any[] = [];
  value: any;
  isFileAttached: boolean = false;
  onFileChange(pFileList: File[]) {
    if (pFileList && pFileList.length >= 0) {
      if (pFileList.length == 1) {
        // this.selectedFile.push(pFileList)
        for (let item of pFileList) {
          this.selectedFile.push(item)
        }
      }
      else {
        for (let item of pFileList) {
          this.selectedFile.push(item)
        }
      }
      console.log(this.selectedFile)
      this.isFileAttached = true;
      this.toastr.success('', 'Document Selected Successfully', {
        timeOut: 3000,
      });
    }
    // this.files = Object.keys(pFileList).map(key => pFileList[key]);

  }

  public fileInfo: File | undefined;
  options: any;
  uploadSub: any;
  referenceNo: any;
  isSuccess: boolean = false;
  Message: any;
  isresponse: boolean = false;
  filename: any;
  packagename: any;
  journalName: any;
  AID: any;
  Date: any;
  Time: any;
  responsedata: any;
  onFileSelected() {
    this.Preloader = true;
    let year = (new Date()).getFullYear()
    let FullDate = (new DatePipe('en-US').transform(new Date(), 'MM-dd-yyyy'));
    let Time = (new DatePipe('en-US').transform(new Date(), 'hh-mm-ss'));
    //JournalAbbr_Year_AID_Manual_Date_Time.zip
    //JGRA_2020_56645_ Manual _2021-07-23_06-10-03.zip
    let Updatedfilename = this.getfinal + '_' + year + '_' + this.articledoi + '_' + 'Manual' + '_' + FullDate + '_' + Time;
    let headers: HttpHeaders;
    let j = 0;
    this.isSuccess = false;
    this.isresponse = false;
    const formData = new FormData();
    for (let item of this.selectedFile) {
      formData.append(j.toString(), item);
      j++;
    }
    headers = new HttpHeaders().set('FileName', Updatedfilename);
    this.options = {
      headers: headers,
      observe: 'events',
      context: 'HttpContext',
    };
    // let BaseUrl = environment.BaseUrl;
    let BaseUrl = '';
    let url = `${BaseUrl}api/Manualuploadpackage`;
    this.uploadSub = this.http.post(url, formData, this.options).subscribe((res: any) => {
      if (res.type == HttpEventType.UploadProgress) {

      } else if (res.type == HttpEventType.Response) {
        console.log(res)
        this.isresponse = true;
        this.responsedata = JSON.parse(res.body)
        this.responsedata
        if (this.responsedata.isSuccess == 'true') {
          this.isSuccess = true;
          this.toastr.success('', 'Package Received', {
            timeOut: 3000,
          });
          this.Preloader = false
          this.Message = this.responsedata.Message
          this.referenceNo = this.responsedata.ReferenceNumber;
        }
        else {
          this.Preloader = false
          this.Message = this.responsedata.Message
          this.toastr.error('', 'error', {
            timeOut: 3000,
          });
        }
      }
    }, error => {
      this.toastr.error('', 'error', {
        timeOut: 3000,
      });
      console.log(error);
    })
  }



  deleteFile(f) {
    // this.files = this.files.filter(function(w){ return w.name != f.name });
    // this._snackBar.open("Successfully delete!", 'Close', {
    //   duration: 2000,
    // });
  }


  openConfirmDialog(pIndex): void {
    // // const dialogRef = this.dialog.open(DialogConfirmComponent, {
    // //   panelClass: 'modal-xs'
    // // });
    // dialogRef.componentInstance.fName = this.files[pIndex].name;
    // dialogRef.componentInstance.fIndex = pIndex;


    // dialogRef.afterClosed().subscribe(result => {
    //   if (result !== undefined) {
    //     this.deleteFromArray(result);
    //   }
    // });
  }

  deleteFromArray(index) {
    console.log(this.files);
    this.files.splice(index, 1);

  }


  OpenOnEdit(id: any) {
    this.isfinal = false;
    if (id == 1)
      this.isarticle = true;
    this.isabstarct = false;
    if (id == 2)
      this.isabstarct = true;
    if (id == 3)
      this.isauthor = true;
    if (id == 4)
      this.isupload = true;
  }

  // deleteAttachedFile(name,i)
  // {
  //   console.log(name);
  //   // this.selectedFile=[]
  //    this.deleteFile[i]
  //  this.isFileAttached=false;

  // }

  deleteAttachedFile(name: any, i: any) {
    {
      this.selectedFile.splice(i, 1);
      if (this.selectedFile.length == 0)
        this.isFileAttached = false;
    }
  }
  onChange(e: any) {
    console.log(e)
    if (e == 1) {
      this.authoruser2 = false
      this.authoruser1 = true
      this.authoruser3 = false
    }
    if (e == 2) {
      this.authoruser2 = true
      this.authoruser1 = false
      this.authoruser3 = false
      this.AuthorFrom2= false;
      this.AuthorFrom3 = false;
      this.AuthorForm = false;

  
  
    }
    if (e == 3) {
      this.authoruser2 = false
      this.authoruser1 = false
      this.authoruser3 = true
      this.AuthorFrom2= false;
      this.AuthorFrom3 = false;
      this.AuthorForm = false;
    }
    if (e == 4) {
      this.authoruser2 = false
      this.authoruser1 = false
      this.authoruser3 = false
      this.AuthorFrom2= false;
      this.AuthorFrom3 = false;
      this.AuthorForm = false;
    }
  }
  onChangeProduct(e:any){
    console.log()
    if (e == 1){
      this.AuthorForm= true

    }
    if (e == 2){
      this.AuthorFrom2 =true;
      this.AuthorForm =false;

    }
    if ( e == 3){
this. AuthorFrom2= false;
this.AuthorFrom3= true;
this.AuthorForm = false;
    }
  }
  submit(){
this.SecondForm = true;
this.AuthorForm= false;
this.UserRoleForm = false;
this.AuthorFrom2= false;
this.AuthorFrom3 = false;
  }
 
  confirmplusurl(){
  let  d=this.userId; 
 
  
    // this.userId=sessionStorage.getItem('UserID')

      // let url = "https://confirmplusdev.easilyplus.io/home?Id="+d
         
      let url = "https://uatconfirmplus.easilyplus.io/home?Id="+d 
      //  let url = "https://qaconfirmplus.icodex.in/home?Id="+d   
        window.open(url, '_blank');
  }
  CleverViewURL(){
  let url ="https://uatcleverview.easilyplus.io/#/?templateID=5688360908eb48ad8e0bb5324aa15d3e114718350"
  window.open(url, '_blank');

  }
  SpeedBe(){
    let url ="https://speedbeportaluat.easilyplus.io/ops_login.aspx?access_token=98we4rgve9u8yfc78jkwd"
    window.open(url, '_blank');
  }
  Corespaceurl(){
    let url ="https://uatcorespace.easilyplus.io/"  
    window.open(url, '_blank');
   
  }
  confirmplusdownloadurl(){
    let url="https://ibeassistantui.icodex.in/#/home/b8b18fd983544a47b1ed96f4b2c6079d"
    window.open(url,'blank');
  }
  back(){
 ;
  }
  
}

